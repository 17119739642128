<script>
import filters from '@/lib/agreement/filters';
export default {
	props: {
		advicePartner: {
			type: Object,
			default() {
				return { address: {} };
			},
		},
		otherPartner: Object,
		agreement: Object,
		relationshipStatus: String,
	},
	computed: {
		advicePartnerFullName() {
			return filters.fullName(this.advicePartner);
		},
		otherPartnerFullName() {
			return filters.fullName(this.otherPartner);
		},

		marriedAct() {
			return this.relationshipStatus === 'Married';
		},

		advicePartnerReflex() {
			return filters.getPronoun(this.advicePartner, 'reflex');
		},

		advicePartnerPoss() {
			return filters.getPronoun(this.advicePartner, 'poss');
		},
		advicePartnerObject() {
			return filters.getPronoun(this.advicePartner, 'object');
		},
		advicePartnerSubject() {
			return filters.getPronoun(this.advicePartner, 'subject');
		},

		otherPartnerReflex() {
			return filters.getPronoun(this.otherPartner, 'reflex');
		},

		otherPartnerPoss() {
			return filters.getPronoun(this.otherPartner, 'poss');
		},
		otherPartnerObject() {
			return filters.getPronoun(this.otherPartner, 'object');
		},
		otherPartnerSubject() {
			return filters.getPronoun(this.otherPartner, 'subject');
		},

		agreementYear() {
			return this.agreement.agreement.year;
		},
	},
};
</script>

<template>
	<div class="legal-advise break-avoid noAnnotations">
		<div class="align-center bold">
			CERTIFICATE OF INDEPENDENT LEGAL ADVICE
		</div>
		<div class="align-center">For {{ advicePartnerFullName }}</div>
		<br />
		<div>
			THIS IS TO CERTIFY that I have been retained by
			{{ advicePartnerFullName }}
			of
			{{ advicePartner.address.city }},
			{{ advicePartner.address.province }}, to advise and have advised
			{{ advicePartnerObject }} with regard to signing the within
			Separation Agreement. I fully read over and explained to
			{{ advicePartnerObject }} the contents of said Separation Agreement
			and advised {{ advicePartnerObject }} of the nature and effect
			thereof, including {{ advicePartnerPoss }} rights as distinguished
			from those of {{ otherPartnerFullName }}.
		</div>
		<br />

		<div>
			{{ advicePartnerFullName }}
			expressed {{ advicePartnerReflex }} to me as understanding and
			appeared to me as fully understanding the nature and the extent of
			this Separation Agreement and that {{ advicePartnerSubject }} stated
			to me and it appeared to me that {{ advicePartnerSubject }} entered
			into the said Separation Agreement under no stress exercised by or
			on behalf of
			{{ otherPartnerFullName }}
			and without any pressure or undue influence or compulsion on the
			part of
			{{ otherPartnerFullName }}
			or anyone on {{ otherPartnerPoss }} behalf.
		</div>
		<br />

		<div>
			I believe that upon entering into the Separation Agreement,
			{{ advicePartnerFullName }}
			was fully advised and informed with regard to all the foregoing
			matters mentioned and may fairly be said to have acted independently
			therein.
		</div>
		<br />

		<div>
			DATED at {{ advicePartner.address.city }},
			{{ advicePartner.address.province }}, this _____ day of
			_____________, {{ agreementYear }}.
		</div>
		<br />

		<p class="align-center bold">ACKNOWLEDGEMENT</p>

		<div>
			I,
			{{ advicePartnerFullName }}
			of
			{{ advicePartner.address.city }},
			{{ advicePartner.address.province }}, hereby acknowledge that:
		</div>
		<ol>
			<li>
				I am executing this document separate and apart from my spouse
				and consider the Separation Agreement fair and reasonable.
			</li>
			<li>
				I am aware of the nature and the effect of this Separation
				Agreement.
			</li>
			<li>
				I am aware of the possible claims to property and maintenance
				that I may have under the <em>Family Law Act</em> of British
				Columbia
				<span v-if="marriedAct"
					>and the <em>Divorce Act</em> (Canada);</span
				>
				and that I intend to give up the claims that I have under those
				Acts to the extent necessary to give effect to this Separation
				Agreement.
			</li>
			<li>
				I am executing this Separation Agreement freely and voluntarily
				without any compulsion on the part of my spouse.
			</li>
		</ol>
		<table style="width: 100%">
			<tr>
				<td style="width: 50%">SWORN BEFORE ME</td>
				<td>)</td>
			</tr>
			<tr>
				<td style="width: 50%">
					at the City of Victoria in the Province
				</td>
				<td>)</td>
			</tr>
			<tr>
				<td style="width: 50%">Province of British Columbia,</td>
				<td>)</td>
			</tr>
			<tr>
				<td style="width: 50%">
					this _____ day of _______________, {{ agreementYear }}
				</td>
				<td>)</td>
			</tr>
			<tr>
				<td style="width: 50%"></td>
				<td>)</td>
			</tr>
			<tr>
				<td style="width: 50%"></td>
				<td>
					)<span style="margin-left: 40px"
						>_______________________________________________</span
					>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					_______________________________________________
				</td>
				<td>
					)<span style="margin-left: 40px">{{
						advicePartnerFullName
					}}</span>
				</td>
			</tr>
			<tr>
				<td style="width: 50%">
					A Commissioner for taking Affidavits within
				</td>
				<td>)</td>
			</tr>
			<tr>
				<td style="width: 50%">British Columbia</td>
				<td>)</td>
			</tr>
		</table>
	</div>
</template>
